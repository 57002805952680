import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import parse from 'html-react-parser'

const SEO = ({ pathname, pageTitle, pageDesc}) => {
  const {
    site: {
      siteMetadata: { url, title, twitterUsername, image, description },
    },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          url
          title
          twitterUsername
          image
          description
        }
      }
    }
  `)
  let parseTitle = ''
  if (pageTitle){
    parseTitle =  parse(pageTitle)
  }

  let parseDesc = ''
  if (pageDesc){
    parseDesc = parse(pageDesc)
  }
  
  //console.log(parseTitle);
  return (
    <Helmet encodeSpecialCharacters={false} defer={false} title={pageTitle ? `${parseTitle} | ${title}` : title}>
      <html lang="en" />
      <link rel="canonical" href={`${url}${pathname}`} />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      
      <meta name="description" content={pageDesc ? `${parseDesc}` : description}></meta>

      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en" />
      <meta property="og:site_name" content={title} />
      <meta property="og:image" content={`${url}${image}`} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitterUsername} />
    </Helmet>
  )
}

export default SEO
