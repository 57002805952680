import React, { Component } from 'react'
import styled from '@emotion/styled'

import gatsbyLogo from '../images/logo-gatsby.svg';
import SVGShape1 from '../components/SVGShape1'

class Footer extends Component {
    render() {
        const Footer = styled.footer`
            padding-top: 10rem;
            padding-bottom: 2rem;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
        `
        const PoweredBy = styled.div`
            grid-column: 1 / span 4;
            display: flex;
            align-items: center;
            font-size: 0.8125em;
            color: var(--c-black);
            position: relative;
            z-index: 3;
            > *{
                display: inline-block;
                margin: 0 0.5rem;
            }
            img{
                display: block;
        }
        `
        const PurpleShape = styled(SVGShape1)`
            position: absolute;
            width: 395px;
            right: -10rem;
            top: -3rem;
        `
        return (
            <Footer>
                <div className="container">
                    <PoweredBy>
                        <span>Powered by</span>
                        <a href="https://gatsbyjs.org" target="_blank" rel="noopener noreferrer"><img src={gatsbyLogo} alt="Gatsby"/></a>
                        <span>+</span>
                        <span role="img" aria-label="with Love">❤️</span>
                    </PoweredBy> 
                </div>
                <PurpleShape fill="#5A5AE5"/>
            </Footer>
        )
    }
}

export default Footer