import React, {useState, useEffect} from 'react'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import styled from '@emotion/styled'

import Header from './header'
import Footer from './footer'

import SVGShape1 from '../components/SVGShape1'

import '../styles/index.scss';

const GreenParallax = styled(Parallax)`
    position: absolute;
    top: 8rem;
    left: -12rem;
    z-index: -1;
`
const Wrapper = styled.div`
    padding-bottom: 250px;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
`

const Layout = (props) => {
    const size = useWindowSize()
    return(
        <ParallaxProvider>
            <Wrapper>
                <Header />
                {
                    size.width > 768 &&
                    <GreenParallax y={[20, -30]} tagOuter="figure">
                        <SVGShape1 fill="#44FFC8"/>
                    </GreenParallax>
                }
                
                {props.children}
                <Footer />
            </Wrapper>
        </ParallaxProvider>
    )
}

export default Layout

// Hook
function useWindowSize() {
	const isClient = typeof window === 'object';
	function getSize() {
	  return {
		width: isClient ? window.innerWidth : undefined,
		height: isClient ? window.innerHeight : undefined
	  };
	}
	const [windowSize, setWindowSize] = useState(getSize);
	useEffect(() => {
	  if (!isClient) {
		  return false;
	  }
	  function handleResize() {
		  setWindowSize(getSize());
	  }
	  window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount
	return windowSize;
}