import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import LogoImg from '../images/logo.svg'

class Header extends Component {
    render() {
        const Header = styled.header`
            align-items: center;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
        `
        const Logo = styled.div`
            grid-column: 1 / 2;
            a{
                display: block;
                img{
                    display: block;
                }
            }
        `

        const Nav = styled.nav`
            grid-column: 2 / 4;
            @media screen and (max-width: 767px){
                grid-column: 2 / span 3;
            }
            ul{
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                @media screen and (max-width: 767px){
                    justify-content: flex-end;
                }
                li{
                    margin-right: 1.5rem;
                    margin-bottom: 0;
                    font-size: 0.8125em;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 500;
                    &:last-child{
                        margin-right: 1.5rem;
                    }
                    a{
                        text-decoration: none;
                        color: var(--c-grey);
                        transition: 300ms;
                        &:hover{
                            color: var(--c-black);
                        }
                        &.active{
                            color: var(--c-tertiary);
                        }
                    }
                }
            }
        `

        const isActive = ({ isPartiallyCurrent }) => {
            return isPartiallyCurrent ? {className: 'active'} : null
        }
        return (
            <Header className="container">
                <Logo>
                    <Link to="/">
                        <img src={LogoImg} alt="Rizqi's Logo"/>
                    </Link>
                </Logo>
                <Nav>
                    <ul>
                        <li>
                            <Link to="/about/" activeStyle={{ color: "var(--c-tertiary)" }}>About</Link>
                        </li>
                        <li>
                            <Link to="/blog/" getProps={isActive}>Blog</Link>
                        </li>
                        <li>
                            <Link to="/til/" getProps={isActive}>Til</Link>
                        </li>
                    </ul>
                </Nav>
            </Header>
        )
    }
}

export default Header