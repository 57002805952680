import React from "react";

const SVGShape1 = ({
  style = {},
  fill = "#000",
  width = "519px", 
  height = "468px",
  className = "",
  transfrom = "translate(-47.000000, -128.000000)",
  viewBox = "0 0 519 468",
  d = "M204.743513,205.053144 C107.665171,246.826248 57.4082865,281.591466 48.2435125,359.553144 C39.0787385,437.514821 102.695968,472.837278 167.243513,524.053144 C231.791057,575.26901 286.066808,609.106287 338.743513,590.053144 C391.420217,571 362.921621,510.775222 420.420217,454.5 C477.918813,398.224778 653.243513,315.553144 511.743513,189.553144 C370.243513,63.5531437 301.821854,163.280039 204.743513,205.053144 Z"
}) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform={transfrom}>
      <path
        fill={fill}
        d={d}
      />
    </g>
  </svg>
);

export default SVGShape1;
